.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Optional: slight movement */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Fade-in class with animation */
.fade-in {
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out forwards;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */

/* App.css */

/* Keyframes for the gradient animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Darker animated gradient */
.animated-gradient {
  background: linear-gradient(270deg, #0f2027, #203a43, #2c5364, #2b5876);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
  min-height: 100vh; /* Full page background */
  width: 100%;
}

/* Subtle bounce animation on hover */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px); /* Less intense bounce */
  }
}

.bounce:hover {
  animation: bounce 0.3s; /* Shorter duration for a more subtle effect */
}

/* Spinning loader */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

/* Typewriter effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.typewriter {
  font-family: monospace;
  overflow: hidden;
  border-right: 3px solid;
  white-space: nowrap;
  width: 0;
  animation: typing 3.5s steps(40, end) forwards, blink 0.75s step-end infinite;
}


